import Vue from "vue";
import App from "./App";
import VueRouter from "vue-router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const bookSearchRoute = () => import("./components/BookSearch");
const flightSearchRoute = () => import("./components/FlightSearch");
const movieSearchRoute = () => import("./components/MovieSearch");
const travelGuidesSearchRoute = () => import("./components/TravelGuidesSearch");
const notFoundRoute = () => import("./components/EmptyComponent");

const routes = [
  {
    path: "/book",
    component: bookSearchRoute,
  },
  {
    path: "/movie",
    component: movieSearchRoute,
  },
  {
    path: "/flight",
    component: flightSearchRoute,
  },
  {
    path: "/travel-guides",
    component: travelGuidesSearchRoute,
  },
  {
    path: "*",
    component: notFoundRoute,
  },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes, // short for `routes: routes`
});

Vue.use(VueRouter);
Vue.use(vuetify);

new Vue({
  render: (h) => h(App),
  vuetify,
  router,
}).$mount("#app");
