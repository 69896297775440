<template>
  <v-app>
    <v-app-bar app style="display: flex; justify-content: space-around">
      <!-- use router-link component for navigation. -->
      <!-- specify the link by passing the `to` prop. -->
      <!-- `<router-link>` will be rendered as an `<a>` tag by default -->
      <v-tabs align-with-title>
        <v-tab key="books" to="/book">Books</v-tab>
        <v-tab key="movies" to="/movie">Movies</v-tab>
        <v-tab key="travel-guides" to="travel-guides">Travel Guides</v-tab>
        <v-tab key="flights" to="/flight">Flights (work in progress)</v-tab>
      </v-tabs>
    </v-app-bar>
    <div id="app">
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: function () {
    return {
      BOOK_SEARCH: "book",
      FLIGHT_SEARCH: "flight",
      MOVIE_SEARCH: "movie",
      TRAVEL_GUIDE_SEARCH: "travel-guides",
      showSearch: "movie", // default
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

a {
  color: #42b983;
  cursor: pointer;
}
</style>
